
// <!-- The core Firebase JS SDK is always required and must be listed first -->
{/* <script src="https://www.gstatic.com/firebasejs/8.2.1/firebase-app.js"></script> */}

// <!-- TODO: Add SDKs for Firebase products that you want to use
     https://firebase.google.com/docs/web/setup#available-libraries -->

{/* <script>
  // Your web app's Firebase configuration */}
  const firebaseConfig = {
    apiKey: "AIzaSyCQ3dky12G9YxSgI5EQ8IQNcPyTdh6LqFM",
    authDomain: "organicstore-6f221.firebaseapp.com",
    databaseURL: "https://organicstore-6f221.firebaseio.com",
    projectId: "organicstore-6f221",
    storageBucket: "organicstore-6f221.appspot.com",
    messagingSenderId: "997177018341",
    appId: "1:997177018341:web:a3b4f0a7053a73a5a5d10c",
    measurementId: "G-6RBLF29RBK"
  };
  // Initialize Firebase
// </script>

export default firebaseConfig;